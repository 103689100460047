<template>
  <div :class="[
    'vform-part-legend',
    'position-relative',
    'w-100',
    'multi-line', 
    config.emphasized ? 'vform-emphasized' : ''
  ]">
    <slot name="dropzone"/>
    <div class="w-100"/>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
    />
    <div class="text"
         contenteditable="false"
         @input="onInput"
         @keydown.enter="leaveEditing(uniqueId)"
         :id="uniqueId"
         @click.stop="doNothing"
    >
      <div v-if="editable && !legend">
        <h3>{{ $t('vform.legendPlaceholder') }}</h3>
      </div>
      <div v-else-if="legend">
        <h3 :class="[
          'd-flex',
          'flex-row',
          'title-' + config.titleAlignment,
        ]">
          {{ $t('vform.legend') }}
        </h3>
        <div v-if="!Object.values(legend).length">
          -
        </div>
        <div :class="[
          'd-flex',
          'flex-column',
          'text-' + config.textAlignment,
          'part-legend'
        ]">
          <div class="d-flex" :key="index" v-for="(item, index) in Object.values(legend)"><div v-if="item.letter" class="letter">{{ item.letter }})</div><div>{{ item.name }}</div></div>
        </div>
      </div>
      <div v-else>
        {{ $t('vform.partLegendLoading') }}
      </div>
  </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import ToWordMixinJs from "@/components/vForm/mixins/toWordMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
export default {
  name: "Headline",
  components: {
    EditorOverlay
  },
  mixins: [ToWordMixinJs, ElementMixin],
  props: {
    /**
     * The slides array coming from the config.json of a vSTAGE package
     * */
    slides: {type: Array, default: null},
    state: {type: Object, required: true},
  },
  data() {
    return {
      localString: "",
      legend: "",
      lastKnownSlide: "",
      loading: false,
    };
  },
  watch: {
    state: {
      deep: true,
      handler(val) {
        if(val.activeSlideUuid !== this.lastKnownSlide) {
          this.loading = true;
          this.lastKnownSlide = val.activeSlideUuid;
          this.setLegend();
          this.loading = false;
        }
      }
    }
  },
  beforeMount() {
    this.setUniqueId();
    this.setLocalString();
  },
  mounted() {
    this.increaseCounter();
    this.addListener(document, "click", this.documentClick, 'headline-click');
    this.setLegend();
  },
  methods: {
    async setLegend() {
      console.log('generating legend...', this.state.projectConfig)
      this.legend = await this.generateLegendForSlides(this.state.projectConfig, [this.state.activeSlideUuid])
    },
    setLocalString() {
      this.localString = this.getLabel(this.config, this.lang);
    },
    documentClick() {
      this.leaveEditing(this.uniqueId);
    },
    onInput(e) {
      this.setLabel(this.config, this.lang, e.target.innerText);
    },
    save() {
      this.editing = false;
    },
    setEditing() {
      if(this.editable) {
        this.editing = true;
        this.setCursor(this.uniqueId);
      }
    },
    validate() {
      return true;
    },
    checkRequired() {
      return true;
    },
    getData() {
      return {data: this.getLabel(this.config, this.lang), type: 'part_legend'};
    }
  }
}
</script>

<style lang="scss" scoped>
.part-legend {
  padding-left: 15px;
  word-break: break-all;
  .letter {
    font-family: 'Inter bold', sans-serif;
    white-space: nowrap;
    min-width: 2.5rem;
  }
}
</style>