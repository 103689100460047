<template>
<div class="rotlocscale-container p-1 border-radius">
  <div class="lighter mb-1">
    This is saving to the instance only (which means: not all screws, but only this one)
  </div>
  <div class="posrotscalesetter">
      <div class="d-flex">
        <label>Position</label>
        <input class="form-text form-text-dark" type="number" v-model="pos[0]"/>
        <input class="form-text form-text-dark" type="number" v-model="pos[1]"/>
        <input class="form-text form-text-dark" type="number" v-model="pos[2]"/>
      </div>
      <div class="d-flex">
        <label>Rotation</label>
        <input class="form-text form-text-dark" type="number" v-model="rot[0]"/>
        <input class="form-text form-text-dark" type="number" v-model="rot[1]"/>
        <input class="form-text form-text-dark" type="number" v-model="rot[2]"/>
      </div>
      <div class="d-flex">
        <label>Scale</label>
        <input class="form-text form-text-dark" type="number" v-model="scale[0]"/>
        <input class="form-text form-text-dark" type="number" v-model="scale[1]"/>
        <input class="form-text form-text-dark" type="number" v-model="scale[2]"/>
      </div>
    <div @click="save" class="settings-button d-inline-block switched-colors">Save</div>
    </div>
</div>
</template>

<script>
import {SpecialUuids} from "@/enum";

export default {
  name: "RotLocScaleSetter.vue",
  props: {
    instanceId: {type: String, required: true},
    projectId: {type: String, required: true},
  },
  data() {
    return {
      pos: [0, 0, 0],
      rot: [0, 0, 0],
      scale: [1, 1, 1],
      SpecialUuids
    }
  },
  beforeMount() {
    console.log('RotLocScaleSetter.vue instanceId: ' + this.instanceId);
    this.loadInstanceMetaValues();
  },
  methods: {
    async loadInstanceMetaValues() {
      console.log('loadInstanceMetaValues');
      const metaVals = await this.$store.dispatch('getProjectInstanceMetaValues', {
        id: this.projectId,
        cid: this.instanceId,
      });
      const posMetaValue = metaVals.find(mv => mv.metaFieldId === SpecialUuids.POSITION_METAFIELD);
      this.pos = posMetaValue ? JSON.parse(posMetaValue.value) : [0, 0, 0];
      const rotMetaValue = metaVals.find(mv => mv.metaFieldId === SpecialUuids.ROTATION_METAFIELD);
      this.rot = rotMetaValue ? JSON.parse(rotMetaValue.value) : [0, 0, 0];
      const scaleMetaValue = metaVals.find(mv => mv.metaFieldId === SpecialUuids.SCALE_METAFIELD);
      this.scale = scaleMetaValue ? JSON.parse(scaleMetaValue.value) : [1, 1, 1];
    },
    async save() {
      this.pos = this.pos.map(p => parseFloat(p));
      this.rot = this.rot.map(r => parseFloat(r));
      this.scale = this.scale.map(s => parseFloat(s));
      await this.$store.dispatch('setInstanceMetaValues', {
        id: this.projectId,
        cid: this.instanceId,
        values: [
          {metaFieldId: SpecialUuids.POSITION_METAFIELD, value: this.pos},
          {metaFieldId: SpecialUuids.ROTATION_METAFIELD, value: this.rot},
          {metaFieldId: SpecialUuids.SCALE_METAFIELD, value: this.scale},
        ]
      });
      this.$emit('updated');
    }
  }
}
</script>

<style scoped lang="scss">
.posrotscalesetter {
  label {
    min-width: 100px;
  }
}
.rotlocscale-container {
  background-color: var(--vform-editor-ui-secondary-color);
}
</style>