<template>
  <three-columns
      :id="$route.params.id"
      back-button-tab-selector="sfxbackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="assemblytab"
  >
    <portal-target slot="leftContent" name="aboveTreeSection"/>
    <div class="col-12 pl-4 d-flex" slot="topContent">
      <h1>{{ $store.getters.getAssemblyName($route.params.id) }}</h1>
      <div v-if="projectId" @click="showGlobalSettings = true" class="ml-3 mt-2 clickable">
        <icon type="cog" />
      </div>
    </div>
    <div slot="leftContent" class="row">
      <model-edit-view-popup
          v-if="isEditing && projectId && hasRoot"
          :instance-id="isEditing"
          :project-id="projectId"
          :has-write-access="hasWriteAccess"
          @abort="() => {isEditing = null;}"
      />
    </div>
    <div v-if="$route.query.nodeId" slot="leftContent" class="darker p-3 mb-1 border-radius">
      <span class="lighter">Showing node:</span> {{ $route.query.nodeId }}<br/>
      <div class="settings-button d-inline-block mt-1" @click="removeNodeId()">Reset Tree</div>
    </div>
    <lazy-loading-hierchy
        v-if="projectId"
        id="sfxEditLinkedDataHierarchy"
        :key="forceReRenderKey2"
        slot="leftContent"
        :fields="[
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Typ',
                        id: 'type',
                        type: 'string',
                    },
                ]"
        :initially-selected-node="$route.query.nodeId"
        :project-id="projectId"
        :has-write-access="hasWriteAccess"
        :organization-id="organizationId"
        close-handler="setAssemblyTreeClosed"
        open-handler="setAssemblyTreeOpen"
        selection-handler="setAssemblyTreeSelected"
        @updated="deleteCache"
    />

    <template slot="middleContent">
      <popup
          v-if="showGlobalSettings"
          @close="() => {showGlobalSettings = false}"
      >
        <assembly-global-settings
            slot="popupMainContent"
            :source-project-id="projectId"
            :organization-id="organizationId"
        />
      </popup>
      <div class="row">
        <div class="col-12 col-md-6 col-xl-12 mb-3 mb-xl-0 float-left float-xl-none">
          <h3 v-if="$store.getters.getAssemblyListOptions($route.params.id).selected_item && $store.getters.getAssemblyListOptions($route.params.id).selected_item.value" class="too-long-text-break">
            {{ $store.getters.getAssemblyListOptions($route.params.id).selected_item.value.displayName }}
          </h3>
        </div>
        <div class="col-12 col-md-6 col-xl-12 mb-2 mt-2">
          <preview
              v-if="$store.getters.getAssemblyListOptions($route.params.id, 'fbxPreview')"
              :downloadable="true"
              :preview-id="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
              :preview-uri="$store.getters.getAssemblyListOptions($route.params.id, 'fbxPreview')"
              :removable="false"
              class="float-right float-xl-none linked-data-preview"
              source="instance"
          />
        </div>
        <download-button
            v-if="$store.getters.getAssemblyListOptions($route.params.id, 'fbxPreview')"
            :object-id="$store.getters.getAssemblyListOptions($route.params.id, 'selected_asset') && $store.getters.getAssemblyListOptions($route.params.id, 'selected_asset').type === 'model' ? $store.getters.getAssemblyListOptions($route.params.id, 'selected_asset').id : $store.getters.getAssemblyListOptions($route.params.id, 'selected')"
            :div-instead-of-button="true"
            class="col-12 xol-md-6 not-absolute clickable"
            download-message=""
            file-key=""
            loading-panel-classes="black"
        ><div class="settings-button darker d-inline-block pt-1 pb-1 mb-1">
          <span>{{ $t('download') }}</span>
          <icon class="ml-1" size="0.9" type="cloud"/>
        </div>
        </download-button>
      </div>
    </template>

    <div v-if="!isLocked && $store.getters.getAssemblyListOptions($route.params.id, 'selected') && projectId"
         slot="middleContent"
         class="row mb-3"
    >
      <div :class="['collapsible mt-4 col-12', tableCollapsed ? 'is-collapsed' : '']">
        <div class="header transparent slim" @click="tableCollapsed = !tableCollapsed"><h3>{{
            $t('localLinkedItems')
          }}</h3>
          <icon type="angle-down"/>
        </div>
        <div id="linked-items" class="content">
          <all-instances
              v-if="$store.getters.getAssemblyListOptions($route.params.id, 'selected') && projectId"
              :id="projectId"
              :key="forceReRenderKey + 3"
              :class="['w-100']"
              :connected-instance="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
              @clickAsset="clickAsset"
          />
        </div>
      </div>
    </div>
    <div v-if="isLocked" slot="rightContent">
      <div class="form-error">
        {{ $t('assemblyIsLocked') }}
      </div>

    </div>
    <div v-if="hasRoot && !isExtracting && !isLocked && $store.getters.getAssemblyListOptions($route.params.id, 'selected') && projectId" slot="rightContent">
      <h3 class="mb-3 mb-xl-5">
        {{ $t('LinkedData') }}
      </h3>
      <tab-switcher
          id="sfxLinkedDataTab"
          :tabs="tabs"
          @setSelected="setSelectedTab"
      />
      <!--id of view-switcher needs to be the same like name of component in order to work -->
      <div
           class="row mt-3 linking-part">
        <content-filter
            v-if="organizationId && projectId"
            :id="id"
            :key="forceReRenderKey + 88"
            :limit-to-organization-ids="[organizationId, $store.getters.getSuperOrg]"
            :org-filter-local="true"
            :org-filtering="true"
            :type="'miniFilter'"
            class="col-12 mb-3 linked-data-filter"
            store-name="Assembly"
            @filter="filter"
        />
        <view-switcher
            v-if="selectedAssetComponent"
            :id="selectedAssetComponent"
            :key="forceReRenderKey + 2"
            :table="true"
            :thumbnails="true"
            class="col-4"
        />
        <div class="w-100"/>
        <pagination
            v-if="projectId"
            :id="storeName + 'pagination'"
            :key="storeName"
            :limit="paginationLimit"
            :total="$store.getters['get' + storeName + 'ListOptions'](listName, 'pagination_items')"
            class="col-7 mt-3 text-center"
            @setActive="loadPaginatedData"
        />
        <asset-table
            v-if="selectedAssetComponent"
            :id="listName"
            :key="forceReRenderKey + 1"
            :asset-instance-limit="getSelectedTab().limit ? getSelectedTab().limit : null"
            :connected-instance="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
            :name="selectedAssetComponent"
            :organization-id="organizationId"
            :pre-content="getSelectedTab().preContent ? getSelectedTab().preContent : null"
            :show-add-button="getSelectedTab().showAddButton ? getSelectedTab().showAddButton : false"
            :show-organization="true"
            :store-name="getSelectedTab().storeName"
            :table-fields="getSelectedTab().tableFields ? getSelectedTab().tableFields : []"
            :thumbnail-fields="getSelectedTab().thumbnailFields ? getSelectedTab().thumbnailFields : []"
            class="col-12"
            @selectCheckbox="clickAsset"
        />
      </div>
    </div>
    <div v-if="!isLocked && hasRoot && !isExtracting" slot="fullContentAfter" class="row">
      <div class="col-12 mt-5">
        <connection-overview v-if="projectId" :key="forceReRenderKey" :project-id="projectId"
                             @deleted="connectionOverviewDeleted"/>
      </div>
    </div>
  </three-columns>
</template>

<script>
import ContentFilter from "../components/widgets/filter/ContentFilter";
import LazyLoadingHierchy from "../components/tree/LazyLoadingHierchy";
import ThreeColumns from "../layouts/ThreeColumns";
import Preview from "../components/preview/Preview";
import TabSwitcher from "../components/widgets/TabSwitcher";
import Pagination from "../components/Pagination";
import ViewSwitcher from "../components/widgets/ViewSwitcher";
import Icon from "../components/Icon";
import AssetTable from "../components/connections/assets/AssetTable";
import {sfxConfig} from '@/components/connections/dataLinkerConfig';
import allInstances from "../components/connections/instances";
import ConnectionOverview from "@/components/connections/ConnectionOverview";
import {mapState} from "vuex";
import DownloadButton from "@/components/forms/DownloadButton";
import {TaskManagerTaskTypes} from "@/enum";
import ModelEditViewPopup from "../components/tree/ModelEditViewPopup";
import Popup from "../components/Popup";
import AssemblyGlobalSettings from "../components/AssemblyGlobalSettings";
export default {
  name: "SFXLinkedData",
  components: {
    ContentFilter,
    Preview,
    ThreeColumns,
    TabSwitcher,
    LazyLoadingHierchy,
    Pagination,
    ViewSwitcher,
    Icon,
    allInstances,
    AssetTable,
    ConnectionOverview,
    DownloadButton,
    ModelEditViewPopup,
    Popup,
    AssemblyGlobalSettings
  },
  data() {
    return {
      showGlobalSettings: false,
      offset: 0,
      listName: this.$route.params.id + 'Instances',
      paginationLimit: 15,
      queryRelevantComponents: 2, //pagination and filter
      queryRelevantInits: 0,
      tableCollapsed: false,
      forceReRenderKey: 0,
      forceReRenderKey2: 0,
      previewPath: null,
      selected: null,
      selectedAssetComponent: '',
      connectedInstance: this.$store.getters.getAssemblyListOptions(this.id, 'selected'),
      projectId: '',
      storeName: '',
      organizationId: '',
      tabs: [],
      fbxFiltering: false,
      hasRoot: false,
      isExtracting: true,
      isLocked: false,
      TaskManagerTaskTypes: TaskManagerTaskTypes,
      hasWriteAccess: false,
      isEditing: null
    };
  },
  computed: mapState({AssemblyListOptions: state => state.dynamicStore.AssemblyListOptions,}),
  watch: {
    AssemblyListOptions: {
      deep: true,
      handler() {
        this.loadPaginatedData(0, false, false);
      }
    },
    queryRelevantInits(val) {
      if (val === this.queryRelevantComponents) {
        this.loadPaginatedData(this.offset);
      }
    },
    '$route.query'(val) {
      if(val.metaInfoId) {
        this.isEditing = val.metaInfoId;
      }
    }
  },
  beforeMount() {
    this.id = this.$route.params.id;
    this.$store.dispatch('clientGetPipelineStatus', {id: this.$route.params.id}).then(res => {
      this.isExtracting = false;
      this.isLocked = false;
      if (res) {
        if (res.task === 'extract') {
          this.isExtracting = true;
        }
        if ([this.TaskManagerTaskTypes.VERSION_CREATE, this.TaskManagerTaskTypes.VERSION_DELETE, this.TaskManagerTaskTypes.VERSION_RESTORE].includes(res.task)) {
          this.isLocked = true;
        }
      }
    });
    if(this.$route.query.metaInfoId) {
      this.isEditing = this.$route.query.metaInfoId;
    }
    // eslint-disable-next-line no-undef
    const redirectNodeId = $cookies.get('redirectNodeId');

    if(redirectNodeId) {
      if(this.$route.query.nodeId) {
        this.isEditing = this.$route.query.nodeId;
      }
    }

    this.loadAssembly()
        .then(async data => {
          this.projectId = data.sourceProjectId ? data.sourceProjectId : null;
          this.organizationId = data.organizationId ? data.organizationId : null;

          this.hasWriteAccess = await this.$store.dispatch('checkTeamOrgPermission', {
            op: 'write',
            organizationId: data.organizationId,
            teams: data.teams,
          });
          return this.loadProjectInstances(this.projectId);
        })
        .then(res => {
          this.hasRoot = !!(res && res.length);
          this.setInitialTabs();
        }).catch(e => {
      if (e.status === 401) {
        this.$router.push('/access-denied');
      } else if (e.status === 404) {
        this.$router.push('/not-found')
      }
    });
  },
  methods: {
    async deleteCache() {
      await this.$store.dispatch('clientDeleteAssemblyProjectInstancesCaching', { id: this.projectId });
      this.$store.dispatch('createNotification', {
        text: 'Cache deleted',
      })
      console.log('cache deleted');
    },
    removeNodeId() {
      let query = Object.assign({}, this.$route.query);
      delete query.nodeId;
      this.$router.replace({query});
      this.forceReRenderKey2++;
    },
    setInitialTabs() {
      this.tabs = sfxConfig;

      if (this.$route.query.sfxLinkedDataTab) {
        this.setSelectedTab(this.$route.query.sfxLinkedDataTab);
      } else {
        this.setSelectedTab(this.tabs[0].name);
      }
    },
    async loadAssembly() {
      return this.$store.dispatch('loadAssembly', {
        id: this.id,
        include: ['teams']
      });
    },
    async loadProjectInstances(projectId) {
      return this.$store.dispatch('clientLoadProjectInstances', {
        id: projectId,
        filter: 'type in node model, level eq 1, parentId eq null',
        include: ['metaValues', 'assetAndMetaSets']
      });
    },
    loadPaginatedData: function (offset, initial, markOnly = false) {
      this.offset = offset;
      let args = {};
      if (args) {
        args.add = {
          paging: {
            [this.listName]: true,
          },
          offset: {
            [this.listName]: offset,
          },
          limit: {
            [this.listName]: this.paginationLimit,
          },

        };
        this.loadDataWithParams(args, initial, markOnly);
      }
    },
    filter: function (params, initial) {
      this.loadDataWithParams(params, initial);
    },
    loadDataWithParams(params, initial, markOnly = false) {
      const selectedInstance = this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected');
      let args = {
        listName: this.listName,
      };
      const paginationAddon = {
        paging: {
          [this.listName]: true,
        },
        offset: {
          [this.listName]: this.offset,
        },
        limit: {
          [this.listName]: this.paginationLimit,
        }
      };
      if (params.add) {
        args.add = params.add ? {...paginationAddon, ...params.add} : paginationAddon
      }
      if (params.remove) {
        args.remove = params.remove
      }

      if (initial && this.storeName) {
        this.queryRelevantInits++;
        this.$store.dispatch(`register${this.storeName}QueryParams`, {listName: this.listName, params: args});
      } else if (markOnly && this.storeName) {
        const items = this.$store.getters[`get${this.storeName}sByListName`](this.listName);
        this.markItems(items, selectedInstance);
      } else if (!initial && this.storeName) {
        this.$store.dispatch(`load${this.storeName}s`, args).then(async items => {
          await this.markItems(items, selectedInstance);
        });
      }
    },
    async markItems(items, selectedInstance) {
      await this.$store.dispatch(`mark${this.storeName}Unselected`, {listName: this.listName, data: items});

      await this.$store.dispatch('getProjectConnections', {id: this.projectId}).then(async connections => {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (connections.filter(conn => {
            return conn.target.assetId === item.id && conn.source.id === selectedInstance
          }).length) {
            await this.$store.dispatch(`mark${this.storeName}Selected`, {
              listName: this.listName,
              data: [{id: item.id}]
            });
          }
        }
        this.forceReRenderKey++;
      });
    },
    setSelectedTab: function (selected) {
      this.queryRelevantInits = 0;
      let selectedTab = this.tabs.find(x => x.name === selected);
      this.selected = selected;
      this.connectedInstance = this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected');
      if (this.selected) {
        this.selectedAssetComponent = selectedTab.assetComponent;
        this.storeName = selectedTab.storeName;
        this.loadPaginatedData(0, false);
      }
    },
    clickAsset: function (linkedDataId, operation) {
      let instanceId = this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected'); //id of instance in hierarchy
      let listName = instanceId + 'Instances';
      this.$log.warn(instanceId + ' ' + this.projectId + ' ' + linkedDataId);
      if (instanceId && this.projectId && linkedDataId) {
        if (operation === 'add') {
          this.$store.dispatch('createNotification', {'text': this.$t('addconnection')});
          let args2 = {
            args: [{
              assetId: linkedDataId,
            }],
            id: this.projectId,
            listName: listName,
          };

          this.$store.dispatch('clientCreateProjectInstances', args2).then(data => {
            let targetId = data[0].id;
            if (instanceId) {
              let args = {
                id: this.projectId,
                listName: listName,
                args: [{
                  targetId: targetId,
                  sourceId: instanceId,
                  name: "sfxDatalink",
                }],
              };
              this.$store.dispatch('createProjectInstanceConnections', args).then(() => {
                //setDatasourceProperty
                this.tableSetUnselected(linkedDataId, true);
                // this.increaseTreeItemSources();
                this.forceReRenderKey++;
              });
            }
          });
        } else if (operation === 'remove') {
          this.$store.dispatch('createNotification', {'text': this.$t('removeconnection')});
          /*
          * linkedDataId = assetId in the left table
          * */
          let args2 = {
            id: this.projectId,
            cid: instanceId,
            filter: 'targetId eq ' + linkedDataId,
            include: 'instanceAndAsset',
          };
          this.$store.dispatch('getProjectInstanceConnections', args2).then(data => {
            let connectionId = null;
            for (let i = 0; i < data.length; i++) {
              let connection = data[i];
              if (connection.source && connection.source.assetId && connection.source.assetId === linkedDataId) {
                connectionId = connection.id;
                break;
              } else if (connection.target && connection.target.assetId && connection.target.assetId === linkedDataId) {
                connectionId = connection.id;
                break;
              }
            }
            let deletionArgs = {
              id: this.projectId,
              args: [connectionId]
            };
            this.$store.dispatch('deleteProjectInstanceConnections', deletionArgs).then(() => {
              this.tableSetUnselected(linkedDataId, false);
              // this.decreaseTreeItemSources();
              this.forceReRenderKey++;
            });
            this.forceReRenderKey++;
          });
        }
      }
      this.deleteCache()
    },
    tableSetUnselected(assetId, bool = false) {
      const dispatchMethod = bool ? `mark${this.storeName}Selected` : `mark${this.storeName}Unselected`;
      this.$store.dispatch(dispatchMethod, {
        listName: this.listName,
        data: [{
          id: assetId,
        }]
      });
    },
    connectionOverviewDeleted() {
      this.deleteCache();
      this.loadPaginatedData(0);
      this.forceReRenderKey++;
    },
    decreaseTreeItemSources() {
      this.$store.dispatch('decreaseAssemblyTreeSourceList', {
        id: this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected'),
        listName: this.listName,
      });
    },
    increaseTreeItemSources() {
      this.$store.dispatch('increaseAssemblyTreeSourceList', {
        id: this.$store.getters.getAssemblyListOptions(this.$route.params.id, 'selected'),
        listName: this.listName,
      });
    },
    getSelectedTab: function () {
      return this.tabs.find(x => x.name === this.selected);
    },
  },
}
</script>
<style lang="scss">
.linked-data-preview {
  min-height: 50vh;
  border: 1px dashed rgba(255,255,255,0.1);
}
.linked-data-filter {
  input {
    width: 100%;
  }
}

.toggler {
  cursor: pointer;

  i {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }


  &.open {
    i {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

}

.collapsed {
  height: 0;
  overflow: hidden;
}

.linking-part > .pagination {
  ul {
    margin: 0 !important;
    float: right;
  }
}

#linked-items {
  table td.field-id-name, table th.field-id-name {
    width: auto;
  }
}

</style>