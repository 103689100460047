<template>
  <div>
      <div class="row" id="form-export">
        <div class="pt-3 pb-4">
          <h1 class="white">Export</h1>
          <div class="lighter mb-1">{{ $t('Please select a format for your export.') }}</div>
          <!-- Word -->
          <div
            :class="['settings-button float-left mr-1 d-inline-block', showExportPrompt === 'Word' ? 'orange-button' : 'switched-colors']"
            @click="
              showExportPrompt = 'Word';
              autoGenerateLegend = false;
              generatePartList = false;
            "
          >
            <icon class="mr-2" type="file-word" />
            <loading-spinner v-if="$store.getters.isWORDGenerating" class="white ml-2 float-right" />
            {{ $t("exportPrompt.word") }}
            <div class="d-inline-block ml-1" v-if="$store.getters.isWORDGenerating">
              ({{ $store.getters.currentWordPage }} / {{ $store.getters.totalWordPages }})
            </div>
          </div>

          <!-- PDF -->
          <div
              :class="['settings-button mr-1 d-inline-block', showExportPrompt === 'PDF' ? 'orange-button' : 'switched-colors']"
            @click="
              showExportPrompt = 'PDF';
              showSessionSelection = false;
            "
          >
            <icon
              v-if="!$store.getters.isPDFGenerating"
              class="mr-2"
              type="pdf"
            />
            <loading-spinner v-if="$store.getters.isPDFGenerating" class="white ml-2 float-right" />
            {{ $t("exportPrompt.pdf") }}
            <div class="d-inline-block ml-1" v-if="$store.getters.isPDFGenerating">
              ({{ $store.getters.currentPDFPage }} / {{ $store.getters.totalPDFPages }})
            </div>
          </div>
        </div>

        <!-- Word Prompt popup -->
        <div
          class="col-12 darker p-3 border-radius"
          v-if="showExportPrompt === 'Word'"
        >
          <h2 class="mb-3">
            {{ $t("exportPrompt.wordOptions") }}
          </h2>

          <div class="export-content">
          <!-- <div class="d-flex flex-row mb-3">
            <h4 class="left-side">{{ $t("exportPrompt.template") }}</h4>
            <drop-down
              :initial-active-value="template[0]"
              :labels="template"
              :values="template"
              class="w-75"
            />
          </div> -->

          <div class="generate-legend-box">
            <label for="generateLegend" class="container vform-editor-body ml-0">
              {{ $t("exportPrompt.autoGenerateLegend") }}
              <input
                id="generateLegend"
                type="checkbox"
                v-model="autoGenerateLegend"
              >
              <span class="checkmark" />
            </label>
          </div>
          <template v-if="autoGenerateLegend">
            <!--DO NOT REMOVE THE FOLLOWING in case we want to use it later in life-->
            <div class="lighter mb-2 mt-2">
              {{ $t("exportPrompt.pleaseSelectAlternativeNames") }}
            </div>
            <div class="d-flex flex-row mb-3">
              <h4 class="left-side">{{ $t("exportPrompt.metaSet") }}</h4>
              <drop-down
                v-if="metaSetIds.length"
                :labels="metaSetNames"
                :values="metaSetIds"
                @select="(selected) => (selectedMetaSet = selected)"
                class="w-75"
              />
            </div>

            <!-- {{ allMetaFields }} -->
            <div v-if="selectedMetaSet" class="d-flex flex-row mb-3">
              <h4 class="left-side">{{ $t("exportPrompt.metaField") }}</h4>
              <div class="d-flex flex-column w-75">
                <div v-for="(item, index) in allMetaFields" :key="'meta-field-' + index" class="d-flex mb-2">
                  <drop-down
                    :labels="metaFieldDescriptions"
                    :values="metaFieldIds"
                    :initial-active-value="item.metaField"
                    @select="(selected) => selectMetaField(selected, index)"
                    class="w-100 mr-2"
                  />
                  <div class="settings-button float-left d-inline" @click="removeMetaFieldSelector(index)">
                    <icon size="1" type="trash" />
                  </div>
                </div>
                <div v-if="selectedMetaSet" class="d-flex flex-row mb-2">
                  <div class="settings-button float-left d-inline" @click="addMetaFieldSelector">
                    <icon size="1" type="plus" />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <label v-if="autoGenerateLegend && selectedMetaSet" for="generatePartList" class="container vform-editor-body ml-0">
            {{ $t("exportPrompt.generatePartList") }}
            <input
                id="generatePartList"
                type="checkbox"
                v-model="generatePartList"
            >
            <span class="checkmark" />
          </label>

          <!-- {{ allPartFields }} -->
          <div v-if="autoGenerateLegend && selectedMetaSet && generatePartList" class="d-flex flex-row mb-3">
            <h4 class="left-side">Meta Field</h4>
            <div class="d-flex flex-column w-75">
              <div v-for="(item, index) in allPartFields" :key="'meta-field-' + index" class="d-flex mb-2">
                <drop-down
                  :labels="metaFieldDescriptions"
                  :values="metaFieldIds"
                  :initial-active-value="item.metaField"
                  @select="(selected) => selectPartField(selected, index)"
                  class="w-100 mr-2"
                />
                <div class="settings-button float-left d-inline" @click="removePartFieldSelector(index)">
                  <icon size="1" type="trash" />
                </div>
              </div>
              <div v-if="generatePartList" class="d-flex flex-row mb-2">
                <div class="settings-button float-left d-inline" @click="addPartFieldSelector">
                  <icon size="1" type="plus" />
                </div>
              </div>
            </div>
          </div>
          <div
            class="form-error"
            v-if="wordGenerationError !== null"
          >
            {{ wordGenerationError }}
          </div>

          <!-- {{ $store.getters.isWORDGenerating }} -->
          <div
            v-if="!$store.getters.isWORDGenerating"
            class="settings-button action-button float-right d-inline"
            @click="exportToWord"
          >
            {{ $t("exportPrompt.download") }}
          </div>
          </div>
          <div class="export-overlay" v-if="$store.getters.isWORDGenerating"/>

        <div
            class="col-12 darker p-3 border-radius"
            v-if="showExportPrompt === 'PDF'"
        >
          <h2 class="mb-3">
            {{ $t("exportPrompt.pdfOptions") }}
          </h2>
          <div class="mb-3">
            <div v-if="logId" @click="showSessionSelection = true" class="settings-button d-inline-block mr-1">Report <icon type="angle-right" /></div>
            <div @click="$emit('exportToPDF')" class="settings-button d-inline-block mr-1">vForm <icon type="angle-right" /></div><info-helper class="ml-1" text="Report: Filled out form, vForm: Empty form" />
            <session-user-selector
                v-if="showSessionSelection"
                class="pb-2 mt-3"
                :log-id="logId"
                @setSessionData="(data) => {$emit('startPDFReport', data)}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import DropDown from "@/components/forms/DropDown.vue";
import LoadingSpinner from "@/components/LoadingSpinner";
import SessionUserSelector from "@/components/vForm/logs/SessionUserSelector.vue";
import InfoHelper from "@/components/InfoHelper.vue";
import {mapState} from 'vuex';

export default {
  name: "VFormExport",
  components: {
    SessionUserSelector,
    Icon,
    DropDown,
    LoadingSpinner,
    InfoHelper
  },
  props: {
    logId: { type: String, default: "" },
    organizationId: {type: String, required: true}
  },
  data() {
    return {
      showSessionSelection: false,
      showExportPrompt: "",

      // Word Export settings
      selectedMetaSet: "",
      metaFields: [],
      allMetaFields: [{}],
      allPartFields: [{}],
      selectedMetaField: "",
      metaFieldAmount: 1,
      // template: ["Tables"],
      autoGenerateLegend: false,
      generatePartList: false,
      wordGenerating: false,
      currentWordPage: 1,
      totalWordPages: 0,

      // PDF Export Settings
      pdfGenerating: false,
      pdfReportGenerating: false,
      currentPDFPage: 1,
      totalPDFPages: 0,
      metaSets: []
    };
  },
  computed: {
    ...mapState({
      wordGenerationError: state => state.vformpage.wordGenerationError,
    }),
    metaSetNames() {
      if (this.metaSets.length > 0) {
        return Object.values(this.metaSets).map((metaSet) => metaSet.name);
      }
      return [];
    },
    metaSetIds() {
      if (this.metaSets.length > 0) {
        return Object.values(this.metaSets).map((metaSet) => metaSet.id);
      }
      return [];
    },
    metaFieldDescriptions() {
      if (this.metaFields.length > 0) {
        return Object.values(this.metaFields).map(
          (metaField) => metaField.description
        );
      }
      return [];
    },
    metaFieldIds() {
      if (this.metaFields.length > 0) {
        return Object.values(this.metaFields).map((metaField) => metaField.id);
      }
      return [];
    },
  },
  watch: {
    autoGenerateLegend() {
      if (!this.autoGenerateLegend) {
        this.selectedMetaSet = "";
        this.selectedMetaField = "";
        this.metaFields = [];
      }
    },
    selectedMetaSet(val) {
      if (val) {
        const item = this.metaSets.find((metaSet) => {
          return metaSet.id === val;
        });
        if (item) {
          this.metaFields = item.metaFields;
          this.selectedMetaField = this.metaFields.length
            ? this.metaFields[0].id
            : null;
        } else {
          this.metaFields = [];
        }
      }
    },
  },
  beforeMount() {
    this.loadMetaSets();
  },
  methods: {
    async loadMetaSets() {
      await this.$store.dispatch('clientLoadMetaSets', {
        include: 'metaFields',
        filter: 'organizationId eq ' + this.organizationId
      }).then(metaSets => {
        this.metaSets = metaSets;
      });
    },
    selectPartField(selected, index) {
      this.$set(this.allPartFields, index, {"metaField": selected, "label": this.metaFields.find(field => field.id === selected).description});
    },
    addPartFieldSelector() {
      this.allPartFields.push({});
    },
    isEmptyObject(obj) {
      return typeof obj === 'object' && obj !== null && Object.keys(obj).length === 0;
    },
    exportToWord() {
      if (this.validateMetaFields()) {
        this.$emit('exportToWord', { 
          metaField: this.allMetaFields, 
          partListMetaFields: this.allPartFields, 
          autoGenerateLegend: this.autoGenerateLegend, 
          generatePartList: this.generatePartList 
        });
      }
    },
    // super ugly validator, but it works
    validateMetaFields() {
      if (this.autoGenerateLegend) {
        if (this.selectedMetaSet == "" || this.selectedMetaSet == null) {
          this.$store.dispatch('updateWordGenerationError', this.$t("vform.metaSetRequired"));
          return false;
        } else {
          if (this.generatePartList) {
            if (this.allMetaFields.every(this.isEmptyObject) || this.allPartFields.every(this.isEmptyObject)) {
              this.$store.dispatch('updateWordGenerationError', this.$t("vform.metaFieldsRequired"));
              return false;
            }
          } 
          if (!this.generatePartList && this.allMetaFields.every(this.isEmptyObject)) {
            this.$store.dispatch('updateWordGenerationError', this.$t("vform.metaFieldsRequired"));
            return false;
          }
        }
      }

      return true;
    },
    removePartFieldSelector(index) {
      if (this.allPartFields.length === 1) {
        this.allPartFields = [{}];
        return;
      }

      this.allPartFields.splice(index, 1);

      this.allPartFields = this.allPartFields.map((field) => ({
        ...field,
        metaField: field.metaField || null,
      }));

      if (this.allPartFields.length === 1 && this.allPartFields[0].metaField === null) {
        this.allPartFields = [{}];
      }
    },
    selectMetaField(selected, index)  {
      this.$set(this.allMetaFields, index, {"metaField": selected, "label": this.metaFields.find(field => field.id === selected).description});
    },
    addMetaFieldSelector() {
      this.allMetaFields.push({});
    },
    removeMetaFieldSelector(index) {
      if (this.allMetaFields.length === 1) {
        this.allMetaFields = [{}];
        return;
      }

      this.allMetaFields.splice(index, 1);

      this.allMetaFields = this.allMetaFields.map((field) => ({
        ...field,
        metaField: field.metaField || null,
      }));
      
      if (this.allMetaFields.length === 1 && this.allMetaFields[0].metaField === null) {
        this.allMetaFields = [{}];
      }
    },
    setPDFTotalPages(pageNo) {
      this.totalPDFPages = pageNo;
    },
    setPDFAddingPage(pageNo) {
      this.currentPDFPage = pageNo;
    },
    setPDFGenerating(bool) {
      this.pdfGenerating = bool;
    },
    setPdfReportGenerating(bool) {
      this.pdfReportGenerating = bool;
    },
    setWordTotalPages(pageNo) {
      this.totalWordPages = pageNo;
    },
    setWordAddingPage(pageNo) {
      this.currentWordPage = pageNo;
    },
    setWordGenerating(bool) {
      this.wordGenerating = bool;
    },
  }
};
</script>

<style lang="scss">
$left-side: 160px;
.newStyle #form-export {
  h1.white {
    color: rgba(255,255,255,0.8);
  }
  h2 {
    font-family: 'Inter bold', sans-serif;
    font-weight: bold;
    text-transform: none;
    color: rgba(255,255,255,0.7);
    font-size: 1.2rem;
  }
}
.left-side {
  width: $left-side;
}
.generate-legend-box {
  border-top: 1px solid rgba(255,255,255,0.4);
  padding-top: 15px;
  margin-top: 15px;
  // margin-left: $left-side - 10px;
}

.export-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  background: rgba(0, 0, 0, 0.5); // Semi-transparent overlay
  z-index: 1000; // Ensure it sits above interactive elements
  cursor: not-allowed; // Change cursor to indicate disabled state
}
</style>
