import Vue from 'vue'
import {SpecialUuids, vformModes} from "@/enum";
import {v4 as uuidv4} from "uuid";
//import $router from '@/router'
//import _ from 'lodash';
const state = {
    images: {},
    imagesLoading: {},
    imagesFailed: {},
    imagesErrors: {},
    vFormMode: vformModes.PRODUCTION,
    vFormUserId: "",
    vFormUserSession: "",
    vFormUserSessionData: {},
    vFormSessionTable: "",
    visitedSteps: [],
    completedSteps: [],
    stepFormData: {},
    vFormTotalSlides: 0,
    vFormTotalSteps: 0,
    vFormCurrentStepNo: 0,
    vFormCurrentSlideNo: 0,
    vFormLogEntries: [],
    vFormActiveSlideUuid: null,
    vFormPushStepId: null,
    vFormPushSlideId: null,
    vFormPushNextStepId: null,
    vFormUserSpecialRole: null,
    vFormPushHistoryUntilStep: null,
    vFormCourseItems: [],
    vFormCourseCats: [],
    visitedElements: {},
    selectedFormLanguage: 'Unknown',
    vformSessionId: null,
    vformUserId: null,
    templates: {},
    isPDFGenerating: false,
    currentPDFPage: 0,
    totalPDFPages: 0,
    isWORDGenerating: false,
    wordGenerationError: null,
    currentWordPage: 0,
    totalWordPages: 0,
    instanceNames: {},
    instanceMetaVals: {},
    partListData: {},
};

const getters = {
    getInstanceMetaVals: (state) => {
        return state.instanceMetaVals
    },
    // Getter to get the instance meta value by id
    getInstanceSquashedMetaValById: (state) => (id) => {
        return state.instanceMetaVals[id];
    },
    getInstanceNames: (state) => {
        return state.instanceNames
    },
    getPartListData: (state) => {
        return state.partListData;
    },
    // Getter to get the instance name by id
    getCaptionInstanceName: (state) => (id) => {
        return state.instanceNames[id];
    },
    isPDFGenerating: state => state.isPDFGenerating,
    currentPDFPage: state => state.currentPDFPage,
    totalPDFPages: state => state.totalPDFPages,
    wordGenerationError: state => state.wordGenerationError,
    isWORDGenerating: state => state.isWORDGenerating,
    currentWordPage: state => state.currentWordPage,
    totalWordPages: state => state.totalWordPages,
    getVFormUserId(state) { // getter for vformUserId
        return state.vformUserId;
    },
    // getter for vformSessionId
    getVFormSessionId(state) {
        return state.vformSessionId;
    },
    // getter for selectedFormLanguage
    getSelectedFormLanguage(state) {
        return state.selectedFormLanguage;
    },
    getBlockTemplates: (state) => {
        return state.templates ? Object.keys(state.templates).map(key => {
            return state.templates[key]
        }) : []
    },
    isElementVisited: (state) => (itemId) => {
        return state.visitedElements[itemId]
    },
    getvFormUserRole: (state) => {
        return state.vFormUserSpecialRole;
    },
    getvFormCourseItems: (state) => {
        return state.vFormCourseItems;
    },
    getvFormCourseCats: (state) => {
        return state.vFormCourseCats;
    },
    getvFormActiveSlideUuid: (state) => {
        return state.vFormActiveSlideUuid;
    },
    getFormDataForElement: (state) => (elementId) => {
        return state.stepFormData && state.stepFormData[elementId] ? state.stepFormData[elementId] : null;
    },
    getLogEntryForCurrentStep: (state) => (stepId) => {
        return state.vFormLogEntries ? state.vFormLogEntries.filter(item => {
            return item.target === stepId;
        }) : [];
    },
    getvFormLogEntries: (state) => {
        return state.vFormLogEntries;
    },
    getvFormCurrentSlide: (state) => {
        return state.vFormCurrentSlideNo + 1;
    },
    getvFormCurrentStep: (state) => {
        return state.vFormCurrentStepNo + 1;
    },
    getvFormTotalSlides: (state) => {
        return state.vFormTotalSlides;
    },
    getvFormTotalSteps: (state) => {
        return state.vFormTotalSteps;
    },
    getvFormUserSessionData: (state) => {
        return state.vFormUserSessionData;
    },
    getvFormUserSessionLastKnownStep: (state) => (projectId) => {
        let lastKnownStep = null;
        const data = state.vFormUserSessionData ?  state.vFormUserSessionData[projectId] : null;
        if(data) {
            const {completedSteps} = data;
            if(completedSteps && completedSteps.length) {
                lastKnownStep =  data.completedSteps[data.completedSteps.length - 1];
            }
        }
        return lastKnownStep;
    },
    getStepFormData: (state) => {
        return state.stepFormData;
    },
    getvFormCompletedSteps: (state) => {
        return state.completedSteps ? state.completedSteps : [];
    },
    getvFormVisitedSteps: (state) => {
        return state.visitedSteps ? state.visitedSteps : [];
    },
    getvFormUserId: (state) => {
        return state.vFormUserId;
    },
    getvFormUserSession: (state) => {
        return state.vFormUserSession;
    },
    getvFormMode: (state) => {
        return state.vFormMode;
    },
    getvFormSessionTable: (state) => {
        return state.vFormSessionTable;
    },
    getFormImageById: (state) => (imageId) => {
        return state.images[imageId] ? state.images[imageId] : null;
    },
    getImagesLoading: (state) => (imageId) => {
        return state.imagesLoading[imageId] ? state.imagesLoading[imageId] : false;
    },
    getImagesFailed: (state) => (imageId) => {
        return state.imagesFailed[imageId] ? state.imagesFailed[imageId] : false;
    },
    getImagesError:(state) => (imageId) => {
        return state.imagesErrors[imageId] ? state.imagesErrors[imageId] : "";
    },
};

const mutations = {
    // Mutation to set the instance meta value with id
    setInstanceMetaVal: (state, payload) => {
        const {id, metaFields} = payload;
        state.instanceMetaVals = {...state.instanceMetaVals, [id]: metaFields};
    },
    // Mutation to set the instance name with id
    setInstanceName: (state, payload) => {
        const {id, name} = payload;
        state.instanceNames = {...state.instanceNames, [id]: name};
    },
    setPartListData: (state, payload) => {
        const { id, data } = payload;
        state.partListData = { ...state.partListData, [id]: data };
    },
    setCurrentPDFPage(state, page) {
        state.currentPDFPage = page;
    },
    setTotalPDFPages(state, total) {
        state.totalPDFPages = total;
    },
    setPDFGenerating(state, isGenerating) {
        state.isPDFGenerating = isGenerating;
    },
    setWORDGenerating(state, isGenerating) {
        state.isWORDGenerating = isGenerating;
    },
    setWordGenerationError(state, error) {
        state.wordGenerationError = error;
    },
    setCurrentWordPage(state, page) {
        state.currentWordPage = page;
    },
    setTotalWordPages(state, total) {
        state.totalWordPages = total;
    },
    setVFormUserId(state, userId) { // mutation(setter) for vformUserId
        state.vformUserId = userId;
    },
    // mutation(setter) for vformSessionId
    setVFormSessionId(state, id) {
        state.vformSessionId = id;
    },
    // mutation(setter) for selectedFormLanguage
    setSelectedFormLanguage(state, language) {
        state.selectedFormLanguage = language;
    },
    setTemplates(state, templates) {

        if (!templates) {
            Vue.set(state, "templates", {});
            return;
        }

        if (templates !== {}) {
            Vue.set(state, "templates", {});
        }

        for (let i = 0; i < templates.length; i++) {
            const templ = templates[i];
            Vue.set(state.templates, templ.key, templ);
        }
    },
    setElementVisited(state, elId) {
        Vue.set(state.visitedElements, elId, true);
    },
    setvFormCourseItems: (state, items) => {
        Vue.set(state, "vFormCourseItems", items)
    },
    setvFormCourseCats: (state, cats) => {
        Vue.set(state, "vFormCourseCats", cats)
    },
    setvFormActiveSlideUuidMutation: (state, uuid) => {
        Vue.set(state, "vFormActiveSlideUuid", uuid)
    },
    clearStepFormDataMutation(state) {
        Vue.set(state, "stepFormData", {})
    },
    setStepFormDataMutation(state, args) {
        const {elementId, data} = args;
        Vue.set(state.stepFormData, elementId, data)
    },
    setvFormCurrentSlideMutation: (state, num) => {
        Vue.set(state, "vFormCurrentSlideNo", num);
    },
    setvFormCurrentStepMutation: (state, num) => {
        Vue.set(state, "vFormCurrentStepNo", num);
    },
    setvFormCompletedStepsMutation(state, steps) {
        Vue.set(state, "completedSteps", steps);
    },
    setvFormVisitedStepsMutation(state, steps) {
        Vue.set(state, "visitedSteps", steps);
    },
    setvFormModeMutation(state, mode) {
        Vue.set(state, "vFormMode", mode);
    },
    setvFormTotalSlidesMutation: (state, num) => {
        Vue.set(state, "vFormTotalSlides", num);
    },
    setvFormTotalStepsMutation: (state, num) => {
        Vue.set(state, "vFormTotalSteps", num);
    },
    setvFormUserSessionMutation(state, session) {
        Vue.set(state, "vFormUserSession", session);
    },
    setvFormUserSessionDataMutation(state, session) {
        const {data, projectId} = session;
        Vue.set(state.vFormUserSessionData, projectId, data);
    },
    clearvFormUserSessionData(state) {
        Vue.set(state, "vFormUserSessionData", {});
    },
    setvFormUserIdMutation(state, mode) {
        Vue.set(state, "vFormUserId", mode);
    },
    setvFormSessionTableMutation(state, tableId) {
        Vue.set(state, "vFormSessionTable", tableId);
    },
    clearCacheMutation(state) {
        Vue.set(state, "images", {});
        Vue.set(state.imagesLoading, {});
    },
    /**
     * @params thingys
     *  - uuid - the uuid of the image asset
     *  - code - the base64 image string
     * **/
    setImageMutation(state, thingys) {
        const {uuid, code} = thingys;
        Vue.set(state.images, uuid, code);
    },
    imagesLoadingMutation(state, imageUuid) {
        Vue.set(state.imagesLoading, imageUuid, true);
    },
    imagesNotLoading(state, imageUuid) {
        Vue.set(state.imagesLoading, imageUuid, false);
    },
    imagesFailedMutation(state, {id, counter}) {
        Vue.set(state.imagesFailed, id, {counter: counter});
    },
    imagesErrorsMutation(state, {id, error}) {
        Vue.set(state.imagesErrors, id, error);
    },
    imagesFailedResetMutation() {
        Vue.set(state, "imagesFailed", {});
        Vue.set(state, "imagesLoading", {});
        Vue.set(state, "imagesErrors", {});
    },
    logEntriesMutation(state, entries) {
        Vue.set(state, "vFormLogEntries", entries);
    },
    setvFormPushHistoryUntilStep(state, stepId) {
        Vue.set(state, "vFormPushHistoryUntilStep", stepId);
    },
    setvFormPushSlideId(state, slideId) {
        Vue.set(state, "vFormPushSlideId", slideId);
    },
    setvFormPushStepId(state, stepId) {
        Vue.set(state, "vFormPushStepId", stepId);
    },
    setvFormPushNextStepId(state, stepId) {
        Vue.set(state, "vFormPushNextStepId", stepId);
    },
    setvFormUserRoleMutation: (state, role) => {
        Vue.set(state, "vFormUserSpecialRole", role);
    },
};

const actions = {
    // Action to commit a mutation to set the instance meta value with id
    updateInstanceMetaVal: ({commit}, payload) => {
        commit('setInstanceMetaVal', payload);
    },
    // Action to commit a mutation to set the instance name with id
    updateInstanceName: ({commit}, payload) => {
        commit('setInstanceName', payload);
    },
    updatePartListData: ({commit}, payload) => {
        commit('setPartListData', payload);
    },
    updateCurrentPDFPage({ commit }, page) {
        commit('setCurrentPDFPage', page);
    },
    updateTotalPDFPages({ commit }, total) {
        commit('setTotalPDFPages', total);
    },
    updatePDFGenerating({ commit }, isGenerating) {
        commit('setPDFGenerating', isGenerating);
    },
    updateWORDGenerating({ commit }, isGenerating) {
        commit('setWORDGenerating', isGenerating);
    },
    updateWordGenerationError({ commit }, error) {
        commit('setWordGenerationError', error);
    },
    updateCurrentWordPage({commit}, page) {
        commit('setCurrentWordPage', page);
    },
    updateTotalWordPages({commit}, total) {
        commit('setTotalWordPages', total);
    },
    updateVFormUserId({commit}, userId) { // action for vformUserId
        commit('setVFormUserId', userId);
    },
    // action for vformSessionId
    updateVFormSessionId({commit}, id) {
        console.log('setting vform session id', id)
        // eslint-disable-next-line no-undef
        $cookies.set('vFormUserSessionId', id);
        commit('setVFormSessionId', id);
    },
    // action for selectedFormLanguage
    updateSelectedFormLanguage({commit}, language) {
        // eslint-disable-next-line no-undef
        $cookies.set('vFormLanguage', language);
        commit('setSelectedFormLanguage', language);
    },
    async loadBlockTemplates({dispatch, commit}, args) {
        const file = await dispatch('clientLoadCombinedAssetPart', args);
        commit('setTemplates', file ? file : {});
        return file;
    },
    setElementVisited({commit}, elId) {
        commit('setElementVisited', elId);
    },
    async upgradeLoggingTable({dispatch}, dataSetId) {
        await dispatch('clientSaveDataset', {
            id: dataSetId,
            schemaVersion: 2
        })
    },
    /*async hasSessionLock({dispatch}, vFormId) {
        // todo: load metaValues of vform, check if lock is open
        console.log('checking session lcok for vFormId')
        const metaValues = await dispatch('clientLoadAssetMetaValues', {
            id: vFormId
        });
        const found = metaValues ? metaValues.find(item => {
            return item.metaFieldId === '62f80d83-5fc1-4f09-b947-e40e670e0b19'
        }) : [];
        if(!filtered) {
            return false;
        } else {
            return !!found.value;
        }
    },
    async setSessionLock({dispatch}, conf) {
      const {lockState, vFormId} = conf;
      const hasLock = await dispatch('hasSessionLock', vFormId);
      if(hasLock) {
          // todo: call until it is open
          console.log('session has a lock: ' + hasLock)
      }
      console.log('setting lock to ' + lockState + ' for vformId ' + vFormId);
    },*/
    getSlideForStep(state, args) {
        let slide = null;
        const {steps, stepId} = args;
        const step = steps.find(item => {
            return item.uuid === stepId;
        })
        if(step) {
            slide = step.linkedSlide;
        }
        return slide;
    },
    setvFormPushStepId({commit}, stepId) {
        commit('setvFormPushStepId', stepId);
    },
    setvFormPushNextStepId({commit}, stepId) {
        commit('setvFormPushNextStepId', stepId);
    },
    setvFormPushHistoryUntilStep({commit}, stepId) {
        commit('setvFormPushHistoryUntilStep', stepId);
    },
    setvFormPushSlideId({commit}, slideId) {
        commit('setvFormPushSlideId', slideId);
    },
    setvFormUserSessionData({commit}, session) {
        console.log('setvFormUserSessionData')
        console.log(session);
        commit('setvFormUserSessionDataMutation', session);
    },
    async matchLogEntriesToStepFormData({dispatch}, entries) {
        for(let i = 0; i < entries.length; i++) {
            const {data} = entries[i];
            for(let j = 0; j < data.length; j++) {
                const {elementId, answers} = data[j];
                let mappedData = [];
                for(let z = 0; z < answers.length; z++) {
                    const item = answers[z];
                    if(item) {
                        if(item.optionId !== undefined) {
                            const {optionId, selected, userId} = item;
                            mappedData.push({
                                uuid: optionId,
                                isSelected: selected ? selected : false,
                                userId
                            });
                        } else {
                            const {userId} = item;
                            mappedData = {
                                answer: item.answer,
                                userId
                            };
                        }
                    }
                }
                await dispatch('setStepFormData', {
                    elementId,
                    data: mappedData
                })
            }
        }
    },
    async loadLogEntries({dispatch}, conf) {
        const {sessionId, logId, filterBy} = conf;
        const results = [];
        const doneTargets = [];
        // logentries have a key: sessionid-stepno (e.g. f946515b-c231-4125-a74d-2604fa29dc3f-4)
        let entries = await dispatch('clientLoadDatasetRowsAsJson', {
            id: logId,
            filter: 'key like ' + sessionId + '%'
        });
        if(filterBy && filterBy.length) {
            entries = entries.length ? entries.filter(item => {
                return filterBy.includes(item.eventType)
            }) : [];
        }
        if(entries.length) {
            entries = entries.sort((a,b) => {
                return b.time - a.time;
            })
            // we can have multiple entries per step and session – only pick the newest version
            entries.map(item => {
                if(!doneTargets.includes(item.target)) {
                    results.push(item);
                    doneTargets.push(item.target);
                }
            })
        }

        //commit('logEntriesMutation', results);
        return results;
    },
    async loadProjectForvForm({dispatch}, conf) {
        const {id, listName} = conf;
        const vForm = await dispatch('loadForm', {
            id,
            listName,
            include: ['metaSets', 'metaValues', 'instances'],
            fields: ['id', 'name'],
        });
        const {instances} = vForm;
        if(instances) {
            const projectId = instances[0].projectId;
            return await dispatch('clientLoadProject', {
                id: projectId
            })
        }
    },
    async loadvFormForProject({dispatch}, conf) {
        const {projectId, listName} = conf;
        const instances = await dispatch('clientLoadProjectInstances', {
            id: projectId
        });
        if(instances && instances.length) {
            const vForm = instances.find(item => {
                return item.type === 'form';
            })
            if(vForm) {
                return await dispatch('loadForm', {
                    id: vForm.assetId,
                    include: ['metaSets', 'metaValues', 'instances'],
                    fields: ['id', 'name', 'content'],
                    listName: listName
                });
            }
        }
        return null;
    },
    getSessionFromStorage() {
        return sessionStorage.getItem("courseOrigin");
    },
    setSessionTovSTAGE({getters, dispatch}, config) {
        const {course, courseOriginProjectId, projectId} = config;
        dispatch('communicateTovStage', {
            "command": "setSession",
            "id": uuidv4(),
            "params": {
                "session": {
                    ...config,
                    course,
                    courseOriginProjectId,
                    target: projectId,
                    sessionId: getters.getvFormUserSession.sessionId,
                    userId: getters.getvFormUserId,
                    mode: getters.getvFormMode,
                    role: getters.getvFormUserRole
                }
            }
        })
    },
    setSessionToStorage({getters}, config) {
        const {course, courseOriginProjectId, projectId} = config;

        // this will set the course as vFormId and the target as projectId
        sessionStorage.setItem("courseOrigin", JSON.stringify({
            ...config,
            course,
            courseOriginProjectId,
            target: projectId,
            sessionId: getters.getvFormUserSession.sessionId,
            userId: getters.getvFormUserId,
            mode: getters.getvFormMode,
            role: getters.getvFormUserRole
        }));
    },
    /*async refreshSession({dispatch, getters}, conf) {
        const {courseOriginProjectId} = conf;
        const existingSession = getters.getvFormUserSession;
        if(existingSession) {
            const {sessionId} = existingSession;
            const newSession = await dispatch('loadSessionById', {
                sessionId,
                sessionTable: getters.getvFormSessionTable,
                courseOriginProjectId: courseOriginProjectId
            });
            dispatch('setvFormUserSession', newSession)
        }
    },*/
    async loadAllProgress({dispatch, commit}, conf) {
      const {sessionId, projectIds} = conf;
      const res = await dispatch('clientLoadCourseProgress', {
          idList: projectIds,
          id: sessionId
      });
      console.log('laodallprogs')
      for(let i = 0; i < res.length; i++) {
          const {projectId, numberOfSteps, completedSteps} = res[i];
          commit('setvFormUserSessionDataMutation', {
              projectId,
              data: {
                  numberOfSteps: numberOfSteps,
                  completedSteps: completedSteps,
              }
          })
      }
    },
    /**
     * @params moduleIds {Array} - an array of projectIds
     * */
    async getLogTablesAndStepsForCourseModules({dispatch}, moduleIds) {
        const metaValues = await dispatch('clientLoadMetaValues', {
            id: SpecialUuids.FORM_METASET,
            cid: SpecialUuids.LOG_TABLE_METAFIELD
        });
        const vformIds = {};
        for(let i = 0; i < moduleIds.length; i++) {
            const projectId = moduleIds[i];
            const vform = await dispatch('loadvFormForProject', {
                projectId,
                listName: 'noname'
            });
            if(vform) {
                let content;
                try {
                    content = JSON.parse(vform.content);
                } catch {
                    content = null;
                }
                vformIds[vform.id] = {
                    projectId,
                    numberOfSteps: content && content.steps ? content.steps.length : 0
                };
            }
        }
        const res = metaValues ? metaValues.filter(item => {
            return Object.keys(vformIds).includes(item.targetId);
        }) : [];
        const values = [];
        for(let i = 0; i < res.length; i++) {
            const {targetId, value} = res[i];
            const {projectId, numberOfSteps} = vformIds[targetId];
            values.push({
                projectId,
                numberOfSteps,
                vformId: targetId,
                logTable: value
            })
        }
        return values;
    },
    async loadSessionProgressForCourseModule({dispatch, commit}, conf) {
      const {logTable, projectId, sessionId, numberOfSteps} = conf;
        let entries = await dispatch('loadLogEntries', {
            logId: logTable,
            sessionId,
            filterBy: ['Aggregation', 'Cancelled']
        });
        const doneSteps = [];
        if(entries) {
            if(entries.length) {
                entries = entries.sort((a,b) => {
                    return b.time - a.time;
                })
                const uniqueSteps = [];

                for(let i = 0; i < entries.length; i++) {
                    const {target, eventType, completed} = entries[i];
                    if(!uniqueSteps.includes(target)) {
                        uniqueSteps.push(target);
                        if(eventType === 'Aggregation' && completed) {
                            doneSteps.push(target);
                        }
                    }
                }
            }
        }
        commit('setvFormUserSessionDataMutation', {
            projectId,
            data: {
                numberOfSteps: numberOfSteps,
                completedSteps: doneSteps,
            }
        })
    },
    async loadSessionById({dispatch}, conf) {
        const {mustBeSameUser, userId, sessionId, sessionTable} = conf;
        const existingSessions = await dispatch('clientLoadDatasetRowsAsJson', {
            id: sessionTable,
            filter: 'key eq ' + sessionId
        });
        let selectedSession;
        if(existingSessions.length && mustBeSameUser) {
            selectedSession = existingSessions.find(item => {
                return item.userId === userId;
            })
        } else if(existingSessions.length) {
            selectedSession = existingSessions[0];
        }
        if(selectedSession) {
            return await dispatch('parseSession', selectedSession);
        } else {
            return null;
        }
    },
    parseSession(item, session) {
        if(session) {
            if(session.data) {
                try {
                    session.data = JSON.parse(session.data)
                } catch (e) {
                    console.log(e);
                    session.data = {};
                }
            }  else {
                session.data = {};
            }
        }
        return session;
    },
    async getSessionsFromDB({dispatch}, conf) {
        const {mustBeSameUser, userId, sessionTable} = conf;
        if(!sessionTable) {
            throw new Error('no Session table given, cannot load sessions')
        }
        const existingSessions = await dispatch('clientLoadDatasetRowsAsJson', {
            id: sessionTable
        }).catch(() => {
            console.log('ERRRROR 2')
        });
        let sessions = [];
        if(existingSessions.length && mustBeSameUser) {
            sessions = existingSessions.filter(item => {
                return item.userId === userId;
            })
        } else {
            sessions = existingSessions;
        }
        for(let i = 0; i < sessions.length; i++) {
            sessions[i] = await dispatch('parseSession', sessions[i]);
        }
        return sessions;
    },
    /**
     * expects data always in object form
     * */
    async saveSessionToDB({dispatch}, conf) {
        const {userId, sessionId, sessionTable} = conf;
        if(!userId) {
            throw new Error('userId is mandatory')
        }
        if(!sessionId) {
            throw new Error('sessionId is mandatory')
        }
        const existingSession = await dispatch('loadSessionById', {
            sessionTable,
            sessionId
        });
        if(!existingSession) {
            const currentTime = Date.now();
            console.log('no existing session, creating a new one...')
            return await dispatch('clientCreateDatasetRows', {
                id: sessionTable,
                values: [{
                    data: {
                        0: userId,
                        1: sessionId,
                        2: currentTime,
                        3: currentTime,
                        //4: data ? JSON.stringify(data) : "{}"
                    },
                    key: sessionId
                }]
            });
        } else {
            // let preData = existingSession.data;
           // let mixedData = Object.assign({}, preData, data);

            return await dispatch('clientUpdateDatasetRow', {
                id: sessionTable,
                datarowId: existingSession.id,
                data: {
                    userId,
                    lastUpdatedTime: Date.now(),
                   // data: mixedData ? JSON.stringify(mixedData) : "{}"
                },
            });
        }
    },
    setvFormUserRole: ({commit}, role) => {
        commit('setvFormUserRoleMutation', role)
    },
    setvFormActiveSlideUuid: ({commit}, uuid) => {
        commit('setvFormActiveSlideUuidMutation', uuid)
    },
    setvFormCourseItems: ({commit}, items) => {
        commit('setvFormCourseItems', items)
    },
    setvFormCourseCats: ({commit}, cats) => {
        commit('setvFormCourseCats', cats)
    },
    /**
     * @params args
     *  - elementId
     *  - data
     * */
    setStepFormData({commit}, args) {
        commit('setStepFormDataMutation', args)
    },
    clearStepFormData({commit}) {
        commit('clearStepFormDataMutation')
    },
    setvFormCompletedSteps({commit}, completedSteps) {
        commit('setvFormCompletedStepsMutation', completedSteps)
    },
    setvFormVisitedSteps({commit}, visitedSteps) {
        commit('setvFormVisitedStepsMutation', visitedSteps)
    },
    setvFormSessionTable({commit}, sessionTable) {
        console.log('setting session talbe to: ' + sessionTable)
        commit('setvFormSessionTableMutation', sessionTable)
    },
    setvFormCurrentSlide: ({commit}, num) => {
        commit('setvFormCurrentSlideMutation', num);
    },
    setvFormCurrentStep: ({commit}, num) => {
        commit('setvFormCurrentStepMutation', num);
    },
    setvFormTotalSlides: ({commit}, num) => {
        commit('setvFormTotalSlidesMutation', num);
    },
    setvFormTotalSteps: ({commit}, num) => {
        commit('setvFormTotalStepsMutation', num);
    },
    /**
     * Sets the vform mode
     * if mode equals test, then logging is disabled automagically
     * */
    setvFormMode({commit}, mode) {
        commit('setvFormModeMutation', mode);
    },
    setvFormUserId({commit}, userId) {
        commit('setvFormUserIdMutation', userId);
    },
    setvFormUserSession({commit}, userSession) {
        console.log('setvFormUserSession')
        console.log(userSession)
        commit('setvFormUserSessionMutation', userSession);
    },
    imagesFailedLoadingReset({commit}) {
        commit("imagesFailedResetMutation");
    },
    clearFormsImageCache({commit}) {
        commit("clearCacheMutation");
    },
    async loadFormImageFromDisk({commit}, args) {
        const {uuid, imageData} = args;
        commit('setImageMutation', {uuid: uuid, code: imageData});
    },
    async resetFormImage({commit}, args) {
        const {id} = args;
        commit('setImageMutation', {uuid: id, code: null});
    },
    async loadFormImage({state, commit, dispatch, getters}, args) {
        const {id, force} = args;
        if (getters.getImagesLoading(id) && !force) {
            return;
        }
        commit('imagesLoadingMutation', id);
        //Vue.set(state.imagesLoading, id, true);
        // if image already exists: don't load it a second time
        if (state[id] && !force) {
            return;
        }
        let key;
        return await dispatch("clientLoadAsset", {id: id})
            .then(async (asset) => {
                const previewUri = asset.previewUri;
                key = previewUri.split("/")[1];
                return await dispatch("clientDownloadAsset", {id: id, key: key})
            }).then(async (data) => {
                let imageUrl;
                if (key.includes('.svg')) {
                    imageUrl = data.text;
                } else {
                    imageUrl = URL.createObjectURL(new Blob([data.text]));
                }
                commit('setImageMutation', {
                    uuid: id,
                    code: imageUrl
                });
                commit('imagesNotLoading', id);
                commit('imagesFailedMutation', {id, counter: 0});
            }).catch(async e => {
                console.log(e);
                console.log('could not load image')
                if (e.statusCode === 401) {
                    commit('imagesErrorsMutation', {id, error: {message: "Permission denied", code: 401}})
                    throw new Error(e.statusCode);
                } else if (e.statusCode === 404) {
                    commit('imagesErrorsMutation', {id, error: {message: "Image not found", code: 404}})
                    return;
                }
                let counter = state.imagesFailed[id] && state.imagesFailed[id].counter ? state.imagesFailed[id].counter : 1;
                if (counter < 4) {
                    console.log('trying again...')
                    commit('imagesNotLoading', id);
                    //Vue.set(state.imagesLoading, id, false);
                    //Vue.set(state.imagesFailed, id, {counter: counter + 1});
                    commit('imagesFailedMutation', {id, counter: counter + 1});
                    setTimeout(async () => {
                        return await dispatch("loadFormImage", args);
                    }, 2000)
                } else {
                    console.log(e.errorCode)
                    commit('imagesNotLoading', id);
                    const message = e.statusCode === 404 ? "Image not found" : "There was a problem loading the image, please reload the form";
                    commit('imagesErrorsMutation', {id, error: {message: message, code: e.statusCode}})
                    console.log('giving up')
                    throw new Error(e);
                }
            });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}