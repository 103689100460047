<template>
  <div />
</template>

<script>
import { Document, Packer, Paragraph, TextRun, Header, Footer, PageNumber, TableRow, TableCell, Table, ImageRun, AlignmentType, WidthType, VerticalMergeType } from "docx";
import { saveAs } from "file-saver";
import vFormAndProjectMixin from "../vFormAndProjectMixin.js.vue";
import DateMixin from "../../mixins/DateMixin.js.vue";
import { vFormControls } from "../../../enum";

/**
 * CONSTANTS FOR WORD FILE STYLING
 */
const TABLE_HEADER_MARGINS = {
  top: 25,
  bottom: 25,
  left: 50,
  right: 50,
};
// 57 twips = 0.1cm
const TABLE_PART_LIST_MARGINS = {
  top: 57,
  bottom: 57,
  left: 57,
  right: 57,
}
const SLIDE_IMAGE_DIMENSIONS = {
  // 0.5 cm
  width: 285,
  height: 214,
};
const LEGEND_FONT_SIZE = 12;
const PART_LIST_TABLE_FONT_SIZE = 18;

let wordData = {};

export default {
  name: "ToWordMixinJs",
  mixins: [vFormAndProjectMixin, DateMixin],
  methods: {
    /**
     * Converts the entire project into a editable word document.
     * Currently, toWord() only generates a word document that contains a header, footer and single table.
     * This aforementioned table contains the following columns:
     * Nr. - The current step.
     * Abbildung - All the slides as screenshots, linked to the current step.
     * Handlungsschritt - Contains all the Headline/Text blocks contained in the local panels in the current step.
     *
     * @param organizationId
     * @param projectId
     * @param slides
     * @param config
     * @param metaFields
     * @param partListMetaFields
     */
    async toWord(organizationId, projectId, slides, config, exportConfig) {
      const { autoGenerateLegend, generatePartList, metaField, partListMetaFields } = exportConfig;
      try {
        await this.initializeData({ metaField, projectId, partListMetaFields });
        this.$store.dispatch('updateTotalWordPages', this.getTotalWordPages(slides));
        this.generateTableHeader();
        for (let i = 0; i < slides.length; i++) {
          if (slides[i].active === false) {
            this.$store.dispatch('updateCurrentWordPage', i + 1);
            continue;
          }
          if (!slides[i].ghostSlide) {
            await this.processSlide(slides[i], projectId, slides, config, autoGenerateLegend, i + 1);
          }
        }
        if (wordData.previousStep) {
          // Make sure you finalize if the loop ended but no new step triggered finalize.
          await this.finalizeCurrentStep(autoGenerateLegend, config);
        }
        let advancedPartListItems = [];
        if (generatePartList) {
          advancedPartListItems = this.addAdvancedPartListTable();
        }

        await this.generateDocument(organizationId, advancedPartListItems);
      } catch (e) {
        console.log("Error: ", e);
        this.$store.dispatch('updateWordGenerationError', "Error while generating the word document.");
      }
    },

    getTotalWordPages(slides) {
      var i = 0;
      slides.forEach(slide => {
        if (!slide.ghostSlide) {
          i++;
        }
      });
      return i;
    },

    /**
     * Processes the slide.
     * If the slide has just landed on a new step, generate a new row with the cached values saved from the past slides.
     * Once generated, clean the arrays, so that we can cache the values for the next step.
     *
     * Else the slide doesn't land on a new step, just save the current slide image and instructions.
     *
     * @param slide
     * @param captionsForSlides
     * @param projectId
     * @param slides
     * @param config
     * @returns {Promise<void>}
     */
    async processSlide(slide, projectId, slides, config, autoGenerateLegend = false, slideIndex) {
      this.$store.dispatch('updateCurrentWordPage', slideIndex)

      const { id } = slide;
      const step = config.steps.find((s) => s.linkedSlides.includes(slide.id));
      const img = await this.generateSlideImage(slide, projectId);
      const isLastSlide = wordData.currentSlide === slides.length;

      if (step || isLastSlide) {
        if (wordData.previousStep) {
          await this.finalizeCurrentStep(autoGenerateLegend, config);
        }
        wordData.previousStep = step;
        wordData.stepIndex++;
      }

      wordData.stepImages.push(img);
      wordData.iteratedSlides.push(id);
    },

    async finalizeCurrentStep(autoGenerateLegend, config) {
      this.renderStepPanels(wordData.previousStep, config);
      if (autoGenerateLegend) {
        await this.generateLegend();
      }
      this.generateNewRow();
      this.clearStepData();
    },

    /**
     * Utility function made to prevent unexpected values from popping up.
     * Made in case the user decides to click on the download button multiple times. Data does not get cleaned once a
     * file gets downloaded.
     *
     */
    async initializeData(config) {
      this.$store.dispatch('updateWordGenerationError', null);
      const { metaField = [], projectId = null, partListMetaFields = [] } = config;
      wordData = {
        instanceNames: {},
        rows: [],
        stepImages: [],
        panelItems: [],
        iteratedSlides: [],

        // Part List relevant items
        partListMetaFields: partListMetaFields,
        partListItems: [],
        partListRows: [],

        previousStep: undefined,
        stepIndex: 0,
        currentSlide: 0,
        organizationName: "",
        organizationWebsite: "",
        captionValues: new Set(),
        selectedMetaFields: metaField,
        vSTAGEConfig: {},
      };
      const rawConfig = await this.loadProjectConfigFileNew(projectId, { loadingMode: this.projectLoadingMode });
      wordData.vSTAGEConfig = await this.preprocessVSTAGEConfig(rawConfig);
    },

    /**
     * Retrieves the specified step, given a slide id.
     * @param slideId
     * @param config
     * @returns {*}
     */
    findStepBySlideId(slideId, config) {
      return config.steps.find((step) => {
        return step.linkedSlides.find((s) => {
          return s === slideId;
        });
      });
    },

    /**
     * Generates a legend with a link to all the captions from the slide images.
     *
     * @param captionsForSlides
     * @returns {Promise<void>}
     */
    async generateLegend() {
      const { iteratedSlides } = wordData;

      if (iteratedSlides && iteratedSlides.length) {
        const legend = await this.generateLegendForSlides(wordData.vSTAGEConfig, iteratedSlides);

        const { selectedMetaFields } = wordData;
        const metaFieldLabels = selectedMetaFields
          .map(metaField => {
            if (metaField.label) {
              return metaField.label;
            }
          })
          .join(", ")

        if (legend.length) {
          wordData.panelItems.push(this.createParagraph("", { alignment: "center", fontSize: LEGEND_FONT_SIZE }));
          wordData.panelItems.push(this.createParagraph(`${metaFieldLabels}:`, { isBold: true, fontSize: LEGEND_FONT_SIZE }));
          // wordData.panelItems.push(this.createParagraph(`Legend (Legende):`, { isBold: true, fontSize: LEGEND_FONT_SIZE }));
        }
        for (let index = 0; index < legend.length; index++) {
          const { name, letter } = legend[index];
          if (letter && letter.trim().length > 0) {
            const formattedLetter = letter.replace(/(\w+-\w+)/g, (match) => {
              return match.replace('-', '\u2011');
            });
            wordData.panelItems.push(new Paragraph({
              children: [
                new TextRun({
                  text: formattedLetter,
                  bold: true,
                  size: LEGEND_FONT_SIZE,
                }),
                new TextRun({
                  text: '\u00A0-\u00A0' + name,
                  size: LEGEND_FONT_SIZE,
                }),
              ],
              style: 'TableCellStyle',
            }));

          } else {
            // this case applies if there is a captioned part that isnt a partlist caption
            wordData.panelItems.push(new Paragraph({
              children: [
                new TextRun({
                  text: '[WARNING] Partlist caption not found - ',
                  size: LEGEND_FONT_SIZE,
                  bold: true,
                }),
                new TextRun({
                  text: name,
                  size: LEGEND_FONT_SIZE,
                }),   
              ],
              style: 'TableCellStyle',
            }));
          }
        }
      } else {
        console.log('No legend generated for this slide.')
      }
    },
    async generateLegendForSlides(projectConfig, slideIds = []) {
      if (!projectConfig) {
        console.log('cannot generate legend without config')
        return;
      }
      const { slideInfo, slideDescriptionInfo, relevantParts } = wordData.vSTAGEConfig;
      const legend = [];
      const slideIdsData = [];

      // linked Caption slides contains
      // the necessary attributes for each slide
      if (relevantParts && Object.keys(relevantParts).length) {
        for (let slideId of Object.keys(relevantParts)) {
          if (slideIds.includes(slideId)) {
            const relevantInstanceIds = relevantParts[slideId].map(item => { return item.instanceId; })
            await this.loadMetadataByInstances(relevantInstanceIds);
            const instanceNames = this.$store.getters.getInstanceNames;
            const partEntries = relevantParts[slideId];

            for (let partEntry of partEntries) {
              const { instanceId, assemblyInstanceId } = partEntry;
              let captionText = slideInfo[slideId] ? slideInfo[slideId][`${assemblyInstanceId}:${instanceId}`] : '';
              console.log('slideINfo', slideInfo);
              if (captionText === '' || captionText === undefined) {
                  captionText = slideInfo[slideId][`${assemblyInstanceId}`]
              } // Fallback
              let amount = slideDescriptionInfo[slideId] ? slideDescriptionInfo[slideId][`${assemblyInstanceId}:${instanceId}`] : '';
              amount = amount && amount.trim() ? this.formatAmountTableCell(amount) : '1';
              
              // const alias = aliases[`${assemblyInstanceId}:${instanceId}`] ? aliases[`${assemblyInstanceId}:${instanceId}`] : null;
              const instanceName = instanceNames[instanceId] ? instanceNames[instanceId] : "no name found " + instanceId;

              legend.push({
                letter: captionText,
                // name: alias ? alias : instanceName,
                name: instanceName,
              });

              slideIdsData.push({
                id: instanceId,
                letter: captionText,
                amount: amount,
              });
            }
          }
        }
        if (this.isPartListOptionSelected()) {
          const partListData = this.$store.getters.getPartListData;
          slideIdsData.forEach(slideData => {
            const matchingPartListEntry = partListData[slideData.id];
            if (matchingPartListEntry) slideData.values = matchingPartListEntry;
          });
          wordData.partListItems.push(slideIdsData);
        }
      }
      return legend;
    },
    /**
     * Renders all the text/headline blocks in all the local panels in a given step.
     * @param step 
     * @param config 
     */
    renderStepPanels(step, config) {
      step.panels.forEach((panel, index) => {
        const elements = this.getElementsByPanel(panel.uuid, config.global, step, index === 0);
        const filteredElements = elements.filter((item) => !item.bottomDropZone);
        this.addStepElements(filteredElements);
      })
    },
    /**
     * Generates a single slide image from a given slide.
     * @param slide 
     * @param projectId 
     * @param i 
     */
    async generateSlideImage(slide, projectId, i = 0) {
      try {
        const img = await this.loadThumbnailNew(projectId, slide, i, {
          loadingMode: this.projectLoadingMode,
          width: 1280,
          height: 960,
          resizeMethod: 'cover',
        });

        return new Paragraph({
          children: [
            new ImageRun({
              type: 'png',
              data: img,
              transformation: SLIDE_IMAGE_DIMENSIONS,
            })
          ],
          spacing: { after: 100 }
        });
      } catch (e) {
        this.$store.dispatch('updateWordGenerationError', "No slide image found.");
        throw Error("No slide image found.");
      }
    },

    /**
     * Generates the Word document and saves it within the client's computer.
     */
    async generateDocument(organizationId, advancedPartListItems = []) {
      const header = await this.retrieveHeader(organizationId);
      const footer = await this.retrieveFooter();
      const table = new Table({ rows: wordData.rows, layout: "fixed" });

      const wordDocument = new Document({
        styles: {
          paragraphStyles: [
            {
              id: 'TableCellStyle',
              name: 'Table Cell Style',
              basedOn: 'Normal',
              next: 'Normal',
              run: {
                font: 'Arial',
                // size: 24, // 12pt font size
              },
              paragraph: {
                indent: {
                  //left: 330, // 0.5 inch (36pt)
                  //hanging: 450, // Ensures text indent
                },
                spacing: {
                  line: 276, // 1.15 line spacing
                },
                contextualSpacing: true,
                keepNext: true,
                keepLines: true,
              },
            },
          ],
          default: {
            document: {
              run: {
                font: "Arial",
              },
              paragraph: {
                alignment: AlignmentType.LEFT
              }
            }
          }
        },
        sections: [{
          properties: {
            page: {
              margin: {
                top: 1818,
                right: 991,
                bottom: 1853,
                left: 1453,
              },
            },
          },
          headers: { default: header },
          footers: { default: footer },
          children: [table]
        },
        {
          properties: {
            page: {
              margin: {
                top: 1818,
                right: 991,
                bottom: 1853,
                left: 1453,
              },
            },
          },
          children: advancedPartListItems
        }]
      });

      const ts = this.getFormattedTimestamp();
      Packer.toBlob(wordDocument)
        .then((blob) => {
          // name of the project
          saveAs(blob, `${this.projectName}-${ts}.docx`);
        });
    },

    /**
     * Helper method to filter out the parantheses and x given a string.
     * 
     * Input: 
     * "(1x)", "(100000000x)", "(9x)", "(999999x)"
     * Expected Output: 
     * "1", "100000000", "9", "999999"
     */
    formatAmountTableCell(input) {
      // Use a regular expression to remove parentheses and the letter 'x'
      return input.replace(/[()x]/g, '');
    },

    getFormattedTimestamp() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-11) and pad with leading zero
      const year = date.getFullYear(); // Get full year

      return `${day}-${month}-${year}`;
    },

    /**
     * Generates the header of the entire table.
     */
    generateTableHeader() {
      const tableHeader = new TableRow({
        children: [
          this.createTableCell([this.createParagraph("Nr. ", { isBold: true, alignment: "center" })], TABLE_HEADER_MARGINS, 550),
          this.createTableCell([this.createParagraph("Abbildung. ", { isBold: true, alignment: "center" })], TABLE_HEADER_MARGINS, 4460), // Set fixed width for the "Abbildung" cell  
          this.createTableCell([this.createParagraph("Handlungsschritt. ", { isBold: true, alignment: "center" })], TABLE_HEADER_MARGINS, 4460),
        ],
      });

      wordData.rows.push(tableHeader);
    },

    /**
     * Creates a single new table cell.
     * @param children
     * @param margins
     * @param width
     * @returns {TableCell}
     */
    createTableCell(children, margins, width = null, options = {}) {
      const { verticalMerge, verticalAlign, bottomBorder } = options;

      const cellOptions = {
        children: children,
        margins: margins,
        verticalAlign: verticalAlign
      };

      if (width) {
        cellOptions.width = {
          size: width,
          type: WidthType.DXA,
        };
      }

      if (verticalMerge) {
        cellOptions.verticalMerge = verticalMerge;
      }

      if (bottomBorder) {
        const { style = "single", size = 4 } = bottomBorder;
        cellOptions.borders = {
          bottom: {
            style: style,
            size: size,
          },
        };
      }

      return new TableCell(cellOptions);
    },

    /**
     * Generates a new row for the table.
     */
    generateNewRow() {
      const stepLabel = this.createParagraph(wordData.stepIndex.toString(), { alignment: "end" });

      // Cell for step nr.
      const stepCell = this.createTableCell([stepLabel], { top: 100, left: 100, bottom: 100, right: 100 });
      const slideImagesCell = this.createTableCell(wordData.stepImages, { top: 100, left: 100, right: 100 }, 2800);
      const instructionCell = this.createTableCell(wordData.panelItems, { top: 100, left: 100, bottom: 100, right: 100 });

      const row = new TableRow({
        children: [
          stepCell,
          slideImagesCell,
          instructionCell,
        ]
      });
      wordData.rows.push(row);
    },

    /**
     * Generates a new row for the part list table which is inserted at the end of the document.
     */
    generateNewPartListRows() {

      const { partListItems, partListMetaFields } = wordData;

      // Add the header row
      const partListHeader = new TableRow({
        children: [
          this.createTableCell([this.createParagraph("Nr", { isBold: true, alignment: "center", fontSize: PART_LIST_TABLE_FONT_SIZE })], TABLE_HEADER_MARGINS, null, { bottomBorder: { style: "single", size: 12 } }),
          this.createTableCell([this.createParagraph("Legend", { isBold: true, alignment: "center", fontSize: PART_LIST_TABLE_FONT_SIZE })], TABLE_HEADER_MARGINS, null, { bottomBorder: { style: "single", size: 12 } }),
          this.createTableCell([this.createParagraph("Stk", { isBold: true, alignment: "center", fontSize: PART_LIST_TABLE_FONT_SIZE })], TABLE_HEADER_MARGINS, null, { bottomBorder: { style: "single", size: 12 } }),
          ...partListMetaFields.map((metaField) => {
            return this.createTableCell([this.createParagraph(metaField.label, { isBold: true, alignment: "center", fontSize: PART_LIST_TABLE_FONT_SIZE })], TABLE_HEADER_MARGINS, null, { bottomBorder: { style: "single", size: 12 } });
          }),
        ],
      });
      wordData.partListRows.push(partListHeader);

      // Iterate through each step
      partListItems.forEach((itemGroup, stepIndex) => {
        if (itemGroup.length > 0) {

          itemGroup.forEach((item, index) => {
            // Edge case: Skip if the letter is empty. This could be due to a normal caption being read and not a partlist caption.
            if (!item.letter || item.letter.trim().length === 0) {
              return;
            }

            const cells = [];

            // Add the `Nr.` column only once per group
            if (index === 0) {
              cells.push(this.createTableCell(
                [this.createParagraph((stepIndex + 1).toString(), { alignment: "center", isBold: true, fontSize: PART_LIST_TABLE_FONT_SIZE })],
                { top: 100, left: 100, bottom: 100, right: 100 },
                550,
                { verticalMerge: VerticalMergeType.RESTART, verticalAlign: "center" }
              ));
            } else {
              // Add an empty cell for subsequent rows in the group
              cells.push(this.createTableCell([], {}, 550, { verticalMerge: VerticalMergeType.CONTINUE }));
            }

            // Letter Column
            cells.push(
              this.createTableCell(
                [this.createParagraph(item.letter || "-", { alignment: "center", fontSize: PART_LIST_TABLE_FONT_SIZE })],
                TABLE_PART_LIST_MARGINS
              )
            );

            // Amount Column
            cells.push(
              this.createTableCell(
                [this.createParagraph(item.amount || "-", { alignment: "center", fontSize: PART_LIST_TABLE_FONT_SIZE })],
                TABLE_PART_LIST_MARGINS
              )
            );

            partListMetaFields.forEach((metaField) => {
              if (item.values && item.values.length > 0) {
                const matchedValue = item.values.find((value) => {
                  return (
                    value &&
                    value.id === metaField.metaField
                  );
                });

                let cellContent = "-";
                if (matchedValue) {
                  cellContent = matchedValue.value;
                }

                cells.push(
                  this.createTableCell(
                    [this.createParagraph(cellContent, { alignment: "start", fontSize: PART_LIST_TABLE_FONT_SIZE })],
                    TABLE_PART_LIST_MARGINS
                  )
                );
              } else {
                cells.push(this.createTableCell([]));
              }
            });

            const row = new TableRow({ children: cells });
            wordData.partListRows.push(row);

          });
        }
      });

    },

    /**
     * Generates the header of the document.
     * Header contains the name and website of the organization that has created the project.
     * @returns {Promise<Header>}
     */
    async retrieveHeader(organizationId) {
      await this.$store.dispatch('loadOrganization', { id: organizationId }).then(organizationData => {
        wordData.organizationName = organizationData.displayName;
        wordData.organizationWebsite = organizationData.url;
      });
      let organizationLabel = '';
      if (wordData.organizationWebsite) {
        organizationLabel = `${wordData.organizationName} - ${wordData.organizationWebsite}`;
      } else {
        organizationLabel = wordData.organizationName;
      }
      return new Header({
        children: [this.createParagraph(organizationLabel, { isBold: true, alignment: "center" })]
      });
    },

    /**
     * Generates the footer of the document.
     * Footer contains a page number that adjusts its value according to which page it is in.
     * @returns {Promise<Footer>}
     */
    async retrieveFooter() {
      return new Footer({
        children: [this.createParagraph(PageNumber.CURRENT, { alignment: "end" })],
      });
    },

    /**
     * Looks through all the blocks in a panel.
     * Retrieves ONLY the Headline and Text blocks and takes its value.
     *
     * @param elements
     * @returns {Promise<void>}
     */
    async addStepElements(elements) {
      for (const element of elements) {
        const text = this.getLabel(element, this.language);

        const { formElementTypeString } = element;

        if (formElementTypeString === vFormControls.HEADLINE) {
          wordData.panelItems.push(this.createParagraph(text, { isBold: true }));
        }

        if (formElementTypeString === vFormControls.TEXT) {
          wordData.panelItems.push(this.createParagraph(text));
        }
      }
    },

    /**
     * Utility function for creating a paragraph. Saves time.
     * @param text
     * @param isBold
     * @param alignment
     * @returns {Paragraph}
     */
    createParagraph(text, { isBold = false, alignment = "start", fontSize = 20 } = {}) {
      const lines = text.split('\n');
      if (lines.length === 1) {
        return new Paragraph({
          children: [new TextRun({ children: [text], bold: isBold, size: fontSize })],
          alignment: alignment,
          spacing: { after: 10 },
        });
      }

      const textRuns = lines.map((line, index) => {
        const textRun = new TextRun({
          text: line,
          bold: isBold,
          break: index < lines.length && index !== 0 ? 1 : 0,
          size: fontSize,
        });
        return textRun;
      });

      return new Paragraph({
        children: textRuns,
        alignment: alignment,
        spacing: { after: 10 }
      });
    },

    /**
     * Prepares the variables for generating the row for the next step.
     */
    clearStepData() {
      wordData.stepImages = [];
      wordData.panelItems = [];
      wordData.iteratedSlides = [];
      wordData.captionValues = new Set();
    },

    /**
     * Retrieves all the captions, based on the assemblyconfig nodes in the config.json file.
     *
     * example:
     * {
     *   some-uuid: {
     *     label: 'A'
     *   }
     * }
     *
     * @returns {Promise<{}>}
     */
    async preprocessVSTAGEConfig(config) {
      /**
       * single slide:
       * {
       *     "attributes": {
       *         "relevance": {
       *             "partList": false
       *         },
       *     }
       *     AssemblyConfig: {
       *       "nodes: {
       *         "some-node-id": {
       *         "caption": {
       *             "text": "",
       *             "font-size": 44.9689255
       *         }
       *       }
       *       }
       *     }
       * }
       * */
      const { slides } = config;
      const globalAssemblyConfig = config.assemblyConfig;
      let globals = {};
      let aliases = {};
      let slideInfo = {};
      let slideDescriptionInfo = {};
      let relevantParts = {};
      if (globalAssemblyConfig) {
        Object.keys(globalAssemblyConfig).map(assemblyId => {
          const { caption,alias } = globalAssemblyConfig[assemblyId];

          if (caption && caption.text) {
            globals[assemblyId] = {
              text: caption.text ? caption.text : " ",
              description: caption.description ? caption.description : " ",
            };
          }
          if (caption && caption.description) {
            globals[assemblyId] = {
              text: caption.text ? caption.text : " ",
              description: caption.description ? caption.description : " ",
            };
          }
          if (alias) {
            aliases[assemblyId] = alias;
          }

          const instances = globalAssemblyConfig[assemblyId].nodes;
          if (instances && Object.keys(instances).length) {
            Object.keys(instances).map(instanceId => {
              const { caption, alias } = instances[instanceId];
              const key = `${assemblyId}:${instanceId}`;
              globals[key] = {}
              if (caption && caption.text) globals[key].text = caption.text ? caption.text : " ";
              if (caption && caption.description) globals[key].description = caption.description ? caption.description : " ";
              if (alias) aliases[key] = alias;
            })
          }
        });
      }
      for (let i = 0; i < slides.length; i++) {
        const { AssemblyConfig, id, attributes } = slides[i];
        console.log('slide ', id);
        if (attributes && attributes.relevance && attributes.relevance.partList) {
          relevantParts[id] = await this.mapRelevantParts(attributes.relevance.partList);
        }
        if (AssemblyConfig) {
          slideInfo[id] = {};
          slideDescriptionInfo[id] = {};
          Object.keys(AssemblyConfig).map(assemblyId => {
            // todo: assembly can also have importantData retrieval, but assemblyId then must be replaced by rootNode instanceId
            
            const { caption } = AssemblyConfig[assemblyId];
            console.log('caption ', caption);
            const key = `${assemblyId}`;

            if (caption && caption.text) {
              slideInfo[id][key] = caption.text;
            } else if (globals[key]) {
              slideInfo[id][key] = globals[key].text || " ";
            }

            if (caption && caption.description) {
              slideDescriptionInfo[id][key] = caption.description;
            } else if (globals[key]) {
              slideDescriptionInfo[id][key] = globals[key].description || " ";
            } else {
              // todo: what is this??
              slideDescriptionInfo[id][key] = "1";
            } 

            const instances = AssemblyConfig[assemblyId].nodes;
            if (instances && Object.keys(instances).length) {
              Object.keys(instances).map(instanceId => {
                const { caption } = instances[instanceId];

                console.log('inst caption ', caption);

                let key;
                if (instanceId) {
                  key = `${assemblyId}:${instanceId}`;
                }

                if (caption && caption.text) {
                  slideInfo[id][key] = caption.text;
                } else if (globals[key]) {
                  slideInfo[id][key] = globals[key].text || " ";
                }

                if (caption && caption.description) {
                  slideDescriptionInfo[id][key] = caption.description;
                } else if (globals[key]) {
                  slideDescriptionInfo[id][key] = globals[key].description || " ";
                } else {
                  // todo: what is this??
                  slideDescriptionInfo[id][key] = "1";
                }
              })
              Object.keys(globals).map(instanceId => {
                if (!slideInfo[id][instanceId]) {
                  slideInfo[id][instanceId] = globals[instanceId].text;
                  slideDescriptionInfo[id][instanceId] = globals[instanceId].description;
                }
              })
            }
          });
        } else {
          console.log('found slide without assembly config', id);
        }
        /*if (slideInfo[id] && !Object.keys(slideInfo[id]).length) {
          console.log('slide without slideInfo', slideInfo[id]);
          delete slideInfo[id];
          delete slideDescriptionInfo[id];
        }*/
      }

      // console.log('globals ', globals);
      // console.log('slideInfo ', slideInfo);
      // console.log('slide description info', slideDescriptionInfo);
      return { slideInfo, slideDescriptionInfo, relevantParts };
    },

    async retrievevSTAGEConfig(projectId) {
      const loadingMode = await this.getProjectLoadingMode(projectId);
      wordData.vSTAGEConfig = await this.loadProjectConfigFileNew(projectId, { loadingMode });
    },

    async mapRelevantParts(partList) {
      const results = [];

      for (const item of partList) {
        const split = item.split(':');
        if (split.length === 2) {
          results.push({
            assemblyInstanceId: split[0],
            instanceId: split[1],
          });
        } else {
          const rootNodeId = await this.getRootInstanceForAssembly(split[0]);
          results.push({
            assemblyInstanceId: split[0],
            instanceId: rootNodeId,
          });
        }
      }

      return results;
    },

    async getRootInstanceForAssembly(instanceId) {
      try {
        let assembly = await this.$store.dispatch("clientGetCrossProjectInstances", {
          include: ['assetAndMetaSets'],
          filter: "id in '" + instanceId + "'",
        });
        assembly = assembly[0];
        if (assembly.asset && assembly.asset.sourceProjectId) {

          let q = await this.$store.dispatch("clientLoadProjectInstances", {
            id: assembly.asset.sourceProjectId,
            filter: "parentId eq null",
          });

          if (q && q.length) {
            return q[0].id;
          }

          return null;
        }
      } catch (error) {
        console.error("Error while resolving root instance for assembly:", error);
        return null;
      }
    },

    /**
     * Utility function that determines if an object is empty.
     * @param object
     * @returns {boolean}
     */
    isObjectEmpty(object) {
      return Object.keys(object).length !== 0;
    },
    isPartListOptionSelected() {
      return wordData.partListMetaFields && wordData.partListMetaFields.length
    },
    /**
     * Given multiple instances. Filter out the caption and metadata.
     * @param allInstances
     * @returns {Promise<*>}
     */
    async loadMetadataByInstances(allInstances) {
      // const instanceNames = JSON.parse(JSON.stringify(this.$store.getters.getInstanceNames));
      // const loadedInstanceIds = Object.keys(instanceNames);
      // if (loadedInstanceIds && loadedInstanceIds.length) {
      //   allInstances = allInstances && allInstances.length ? allInstances.filter(id => {
      //     return !loadedInstanceIds.includes(id)
      //   }) : [];
      // }
      if (!allInstances || !allInstances.length) {
        // return new Promise((resolve) => { resolve(); })
        return Promise.resolve();
      }
      let filterString = "id in ";
      if (allInstances.length === 1) {
        filterString = "id eq " + allInstances[0];
      } else {
        for (let instance of allInstances) {
          filterString += `'${instance}' `;
        }
      }

      const instances = await this.$store.dispatch("clientGetCrossProjectInstances", { include: ['metaFields'], filter: filterString });
      for (let i = 0; i < instances.length; i++) {
        const { id, metaFields, displayName } = instances[i];
        const { selectedMetaFields, partListMetaFields } = wordData;
        // if(selectedMetaFields) {
        // MARK: here is where the metafield is selected and filtered, through filterMetafield.

        const mappedMetaValues = this.mapMetaValues(metaFields);

        const targetMetaFields = this.filterMetaField(mappedMetaValues, selectedMetaFields);

        let isMissing = false;

        const combinedMetaValues = targetMetaFields
          .map(targetField => {
            if (!targetField || !targetField.value || targetField.value === undefined) {
              isMissing = true;
              return "[missing]";
            }
            return targetField.value;
          })
          .join(" - ");
        const finalResult = isMissing
          ? `${combinedMetaValues} (default name: ${displayName})`
          : combinedMetaValues;

        await this.$store.dispatch('updateInstanceName', {
          id,
          name: finalResult
        });

        if (partListMetaFields) {
          const targetPartListFields = this.filterMetaField(mappedMetaValues, partListMetaFields);
          // Process and handle missing or valid values safely
          const combinedPartListValues = targetPartListFields
            .map(targetField => {
              if (!targetField || !targetField.value || targetField.value === undefined) {
                return "[missing]"; // Default value for missing fields
              }
              return targetField;
            });
          await this.$store.dispatch('updatePartListData', {
            id,
            data: combinedPartListValues
          });
        }
      }
      return this.$store.getters.getInstanceNames;
    },
    mapMetaValues(metaFields) {
      // Initialize the output object
      const output = {};

      // Iterate through each item in the input array
      metaFields.forEach(item => {
        // Check if item has valid structure
        if (item.id && item.metaValues) {
          // Extract the first metaValue and map it to the id in the output object
          output[item.id] = item.metaValues.value;
        }
      });

      return output;
    },

    /**
     * Checks to see if a specific metafield exists.
     *
     * @param mappedMetaValues
     * @param selectedMetaFields
     * @returns {*}
     */
    filterMetaField(mappedMetaValues, selectedMetaFields) {
      const filteredOutput = [];
      selectedMetaFields.forEach(mf => {
        const id = mf.metaField;
        if (Object.prototype.hasOwnProperty.call(mappedMetaValues, id)) {
          filteredOutput.push({ id: id, value: mappedMetaValues[id] });
        } else {
          filteredOutput.push({ id: id, value: undefined });
        }
      });

      return filteredOutput;
    },

    addAdvancedPartListTable() {
      this.generateNewPartListRows();

      const partListTable = new Table({
        rows: wordData.partListRows,
        layout: "autofit"
      });

      return [partListTable];
    },
  }
}
</script>
